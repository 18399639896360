import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Moment } from "moment";

export interface getVocHourAvgHistorical_item {
  id: number;
  dev_id: string;
  location_id: number;
  date: string;
  hour: number;
  temperature_min: number;
  temperature_max: number;
  temperature_avg: number;
  voc_min: number;
  voc_max: number;
  voc_avg: number;
  created_at: string;
}

export interface getVocHourAvgHistorical_Returns {
  status: string;
  count: number;
  connected_to: string;
  data: getVocHourAvgHistorical_item[];
}

export const getVocHourAvgHistorical = async (
  // dev_id: string,
  start_datetime: Moment,
  end_datetime: Moment
): Promise<AxiosResponse<getVocHourAvgHistorical_Returns>> => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: `http://monster1.distronix.in:8100/api/v1.1/get_hours_avg?location_id=1&start_time=${start_datetime.format(
      "YYYY-MM-DD HH:mm:ss"
    )}&end_time=${end_datetime.format("YYYY-MM-DD HH:mm:ss")}`,
  };
  const result: AxiosResponse<getVocHourAvgHistorical_Returns> = await axios(
    config
  );
  return result;
};
