import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getHourAvgHistorical_item } from "../../api/getHourAvgHistorical";
import { getHourlyAQI_returns_data_item } from "../../api/getHourlyAQI";

interface dataItem extends getHourAvgHistorical_item {
  serial: number;
}

export type getHourlyAQIData = dataItem[];

export const HourlyAQISliceInitialState: getHourlyAQIData = [];

export const HourlyAQISlice = createSlice({
  name: "hourly",
  initialState: HourlyAQISliceInitialState,
  reducers: {
    populate: (
      state: getHourlyAQIData,
      action: PayloadAction<getHourlyAQIData>
    ) => {
      return action.payload;
    },
    empty: (
      state: getHourlyAQIData,
      action: PayloadAction<void>
    ) => {
      return HourlyAQISliceInitialState;
    },
  },
});

export const { populate, empty } = HourlyAQISlice.actions;
export const HourlyAQIReducer = HourlyAQISlice.reducer;