import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { populate } from "./app/DeviceSlice";
import { useAppDispatch } from "./app/hooks";
import { changeDevice } from "./app/InputSlice";
import { RootState } from "./app/store";
import Header from "./components/Header";
import DailyAQI from "./pages/daily";
import HourlyAQI from "./pages/hourly";
import { SignIn } from "./pages/user/SignIn";
import HistoricAQI from "./pages/history";
import { HardCodedDevices } from "./api/mocks/HardCodedDevices";
// import { getDeviceList } from "./api/getDeviceList";

function App() {
  const { state } = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const loadDevices = async () => {
      // const devicesData = await getDeviceList();
      // const devices = devicesData.data.data;
      const devices = HardCodedDevices;
      console.log(devices);
      dispatch(populate(devices));
      dispatch(changeDevice(devices[0]));
    };
    loadDevices();
  }, []);
  return (
    <Router>
      <div
        className="flex h-full min-h-screen flex-col justify-start items-stretch"
        style={{
          backgroundImage:
            "linear-gradient(118deg, rgba(231,230,255,1) 0%, rgba(235,255,168,1) 35%, rgba(255,200,110,1) 72%, rgba(255,242,220,1) 100%)",
        }}
      >
        <Header />
        <div className="flex flex-1 justify-start flex-col items-stretch">
          <Switch>
            <Route path="/signin" exact component={SignIn} />
            <Route path="/daily" exact component={DailyAQI} />
            <Route path="/hourly" exact component={HourlyAQI} />
            <Route path="/history" exact component={HistoricAQI} />
            <Route path="/">
              {state === "guest" ? (
                <Redirect to="/signin" />
              ) : (
                <Redirect to="/daily" />
              )}
            </Route>
          </Switch>
          {/* {state==='loggedIn'?<AQIDangerRanges/>:null} */}
        </div>
      </div>
    </Router>
  );
}

export default App;
