import React from 'react'

const HistoricAQI = () => {
    
    return (
        <div className="">
            
        </div>
    )
}

export default HistoricAQI
