import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { MockSignIn } from "../../api/mocks/signin";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { login, updateLogStatus, UserInputCredentials } from "./UserSlice";

export const SignIn = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserInputCredentials>();
  const { state } = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  console.log(watch('userId'));
  const onSubmit: SubmitHandler<UserInputCredentials> = async (data) => {
    await MockSignIn(data)
      .then((r) => {
        dispatch(login(r));
      })
      .catch((e) => {
        console.log(e);
        dispatch(updateLogStatus("guest"));
      });
  };
  if(state==="loggedIn") return <Redirect to="/daily" />
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <form
        className="flex flex-col justify-center items-stretch p-8 border-2 border-gray-200 rounded-md bg-blend-overlay bg-white bg-opacity-25 gap-4"
        style={{ minWidth: 400 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col justify-start items-start w-full">
          <div className="p-1 text-gray-600 text-lg">User ID</div>
          <input
            type="email"
            id="userId"
            {...register("userId", { required: true })}
            className="rounded-md w-full p-2 text-lg shadow-md"
          />
            <span className="text-xs text-red-500 px-1">{!!errors.userId?errors.userId.message:''}</span>
        </div>
        <div className="flex flex-col justify-start items-start w-full">
          <div className="text-lg p-1 text-gray-600">Password</div>
          <input
            type="password"
            id="userId"
            {...register("password", { required: true })}
            className="rounded-md w-full p-2 text-lg shadow-md"
          />
            <span className="text-xs text-red-500 px-1">{!!errors.password?errors.password.message:''}</span>
        </div>
        <input
          type="submit"
          className="p-4 bg-gray-300 text-gray-700 rounded-md shadow-md font-semibold font-sans hover:text-gray-200 hover:bg-gray-700 transition-colors duration-500"
          value="Sign In"
        />
      </form>
    </div>
  );
};
