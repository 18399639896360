import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Moment } from "moment";

export interface getVocData_Item {
  id: number;
  dev_id: string;
  location_id: number;
  date: string;
  hour: number;
  temperature_min: number;
  temperature_max: number;
  temperature_avg: number;
  voc_min: number;
  voc_max: number;
  voc_avg: number;
  created_at: string;
}

export interface getVocData_Returns {
  status: string;
  connected_to: string;
  count: number;
  data: getVocData_Item[];
}

export const getVocData = async (): Promise<
  AxiosResponse<getVocData_Returns>
> => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "http://monster1.distronix.in:8100/api/v1.1/get_24h_avg?location_id=1",
  };
  const result: AxiosResponse<getVocData_Returns> = await axios(config);
  return result;
};
